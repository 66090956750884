import "../styles/globals.css";
import "../styles/split-text.css";
import "../styles/sticky-heading.css";
import "../styles/scroll.css";
import "../styles/article-list.css";
import "../styles/job-list.css";
import "../styles/transition-item.css";
import "../styles/gradient-line.css";
import "../styles/menu-background.css";

import { useEffect } from "react";

import { ApolloProvider } from "@apollo/client/react";
import AppContextProvider from "../components/AppContext";
import Box from "../components/Box";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import { ThemeProvider } from "styled-components";
import ViewportSizeProvider from "../components/ViewportSizeContext";
import client from "../core/apollo-client";
import smoothscroll from "smoothscroll-polyfill";
import { theme } from "../core/theme";
import VideoLightbox from "../components/VideoLightbox";
import { onLCP, onFID, onCLS } from "web-vitals";

function App({ Component, pageProps }) {
  useEffect(() => {
    smoothscroll.polyfill();
    const isMobile = window.navigator.userAgent.match(
      /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i
    );
    if ("ontouchstart" in document.documentElement && isMobile) {
      document.body.classList.add("is-touch");
    }
    console.log("Trailstone version 008");
  }, []);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <ViewportSizeProvider>
          <AppContextProvider>
            <VideoLightbox />
            <Box
              backgroundColor="trailstoneNavy"
              minHeight="100vh"
              display="flex"
              flexDirection="column"
            >
              <Nav />
              <Box
                position="relative"
                zIndex={1}
                flex={1}
                display="flex"
                flexDirection="column"
              >
                <Component {...pageProps} />
              </Box>
              <Box position="relative" zIndex={2}>
                <Footer />
              </Box>
            </Box>
          </AppContextProvider>
        </ViewportSizeProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;

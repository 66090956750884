import { CUSTOMER_PORTAL_URL, CORPORATE_URL } from "../core/urls";
import { useEffect, useState } from "react";

import Box from "./Box";
import SmallWhiteCaps from "./SmallWhiteCaps";
import Chevron from "../assets/chevron.svg";

import { useRouter } from "next/router";
import MenuItem from "./MenuItem";

function CustomersMenu({ locale }) {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setIsOpen(false);
  }, [router.asPath]);

  const customersMenu = {
    en: {
      customers: "Customers",
      customerPortal: "Customer portal",
      corporate: "Corporate",
    },
    de: {
      customers: "Kunden",
      customerPortal: "Kundenportal",
      corporate: "Für Unternehmen",
    },
    fr: {
      customers: "Clients",
      customerPortal: "Portail clients",
      corporate: "Corporate",
    },
    ja: {
      customers: "Customers",
      customerPortal: "カスタマーポータル",
      corporate: "Corporate",
    },
  };

  const customersMenuContent = customersMenu[locale];

  return (
    <Box
      position="relative"
      ml={35}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      height={75}
    >
      <Box display="flex" height="100%" alignItems="center">
        <Box as="button" py="12px" display="block">
          <SmallWhiteCaps color="white" cursor="pointer">
            {customersMenuContent.customers}
          </SmallWhiteCaps>
        </Box>
        <Box pl="5px" display="flex" justifyConent="center" alignItems="center">
          <Chevron />
        </Box>
      </Box>

      <Box
        position="absolute"
        top={75}
        width={212}
        left={-53}
        borderRadius={15}
        border="1px solid rgba(245, 245, 244, 0.15)"
        backgroundColor="gulfBlue"
        px={20}
        py={16}
        transition="opacity 100ms, transform 100ms cubic-bezier(0.16, 1, 0.3, 1)"
        opacity={isOpen ? 1 : 0}
        transform={isOpen ? "translate3d(0,0,0)" : "translate3d(0,10px, 0)"}
        pointerEvents={isOpen ? "all" : "none"}
      >
        <Box
          position="absolute"
          top={-8}
          width="100%"
          left={0}
          display="flex"
          justifyContent="center"
        >
          <Box
            width="15px"
            height="15px"
            borderTopColor="rgba(245, 245, 244, 0.15)"
            borderTopWidth="1px"
            borderTopStyle="solid"
            borderRightColor="rgba(245, 245, 244, 0.15)"
            borderRightWidth="1px"
            borderRightStyle="solid"
            backgroundColor="gulfBlue"
            transform="rotate(-45deg)"
          />
        </Box>
        <MenuItem
          href={CUSTOMER_PORTAL_URL}
          label={customersMenuContent.customerPortal}
          external={true}
        />
        <MenuItem
          href={CORPORATE_URL}
          label={customersMenuContent.corporate}
          lastOfType
        />
      </Box>
    </Box>
  );
}

export default CustomersMenu;

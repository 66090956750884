import { useEffect, useRef } from "react";

import Box from "./Box";
import LottiePlayer from "./LottiePlayer";
import electronFooter from "../assets/electron_footer.json";
import { useInView } from "react-intersection-observer";

function FooterAnim() {
  const playerRef = useRef();
  const { ref: containerRef, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (playerRef.current) {
      if (inView) {
        playerRef.current.play();
      } else {
        if (playerRef.current.state.instance) {
          playerRef.current.state.instance.goToAndStop(0);
        }
      }
    }
  }, [inView]);

  return (
    <Box ref={containerRef}>
      <LottiePlayer
        ref={playerRef}
        autoplay={false}
        loop={false}
        src={electronFooter}
        rendererSettings={{
          preserveAspectRatio: "xMidYMid meet",
        }}
        pauseWhenNotInView={false}
      />
    </Box>
  );
}

export default FooterAnim;

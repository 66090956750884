import { forwardRef, useEffect } from "react";

import Box from "./Box";
import { Player } from "@lottiefiles/react-lottie-player";
import { useInView } from "react-intersection-observer";

const LottiePlayer = forwardRef(
  (
    { src, loop, autoplay, rendererSettings, style, pauseWhenNotInView },
    ref
  ) => {
    const { ref: inViewRef, inView } = useInView();

    useEffect(() => {
      // Pause and clean up on unmount
      return () => {
        if (ref.current) ref.current.pause();
      };
    }, []);

    useEffect(() => {
      if (ref.current.state.instance) {
        ref.current.state.instance.setSubframe(false);
      }
    }, [ref]);

    useEffect(() => {
      if (pauseWhenNotInView && ref.current) {
        if (inView) {
          ref.current.play();
        } else {
          ref.current.pause();
        }
      }
    }, [inView]);

    return (
      <Box ref={inViewRef}>
        <Player
          ref={ref}
          src={src}
          loop={loop}
          autoplay={autoplay}
          rendererSettings={rendererSettings}
          keepLastFrame={true}
          style={style}
        />
      </Box>
    );
  }
);

export default LottiePlayer;

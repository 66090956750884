import Box from "./Box";
import Link from "next/link";

import SmallWhiteCaps from "./SmallWhiteCaps";
import { useRouter } from "next/router";

function NavItemText({ href, label, external, locale, ...props }) {
  const router = useRouter();

  if (external) {
    return (
      <a
        href={href}
        target="_blank"
        style={{ padding: "12px 0", display: "block" }}
      >
        <SmallWhiteCaps
          py="6px"
          color={href === router.pathname ? "lime" : "white"}
          {...props}
        >
          {label}
        </SmallWhiteCaps>
      </a>
    );
  }

  return (
    <Link href={href} passHref locale={locale}>
      <Box as="a" py="12px" display="block">
        <SmallWhiteCaps
          color={href === router.pathname ? "lime" : "white"}
          {...props}
        >
          {label}
        </SmallWhiteCaps>
      </Box>
    </Link>
  );
}

export default NavItemText;

import { useEffect, useState } from "react";

import Box from "./Box";
import CapSize from "./CapSize";
import Globe from "../assets/globe.svg";
import Link from "next/link";
import { useRouter } from "next/router";

function MenuItem({ href, label, lastOfType, active, locale }) {
  return (
    <Box borderBottom={lastOfType ? "" : "1px solid rgba(245, 245, 244, 0.15)"}>
      <Link href={href} passHref locale={locale}>
        <CapSize
          as="a"
          transition="color 300ms"
          py="14px"
          display="block"
          hover="color: #C7FF00;"
          color={active ? "#C7FF00" : "white"}
        >
          {label}
        </CapSize>
      </Link>
    </Box>
  );
}

function LocalesMenu({ locale }) {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setIsOpen(false);
  }, [router.asPath]);

  const localesMenu = {
    en: "English",
    de: "Deutsch",
    fr: "Français",
    ja: "日本",
  };

  return (
    <Box
      position="relative"
      pl={35}
      pr={35}
      onClick={() =>
        document.body.classList.contains("is-touch") ? setIsOpen(!isOpen) : ""
      }
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      height={75}
    >
      <Box display="flex" height="100%" alignItems="center">
        <Globe />
      </Box>

      <Box
        position="absolute"
        top={75}
        width={212}
        left={-65}
        borderRadius={15}
        border="1px solid rgba(245, 245, 244, 0.15)"
        backgroundColor="gulfBlue"
        px={20}
        py={16}
        transition="opacity 100ms, transform 100ms cubic-bezier(0.16, 1, 0.3, 1)"
        opacity={isOpen ? 1 : 0}
        transform={isOpen ? "translate3d(0,0,0)" : "translate3d(0,10px, 0)"}
        pointerEvents={isOpen ? "all" : "none"}
      >
        <Box
          position="absolute"
          top={-8}
          width="100%"
          left={0}
          display="flex"
          justifyContent="center"
        >
          <Box
            width="15px"
            height="15px"
            borderTopColor="rgba(245, 245, 244, 0.15)"
            borderTopWidth="1px"
            borderTopStyle="solid"
            borderRightColor="rgba(245, 245, 244, 0.15)"
            borderRightWidth="1px"
            borderRightStyle="solid"
            backgroundColor="gulfBlue"
            transform="rotate(-45deg)"
          />
        </Box>
        <MenuItem
          label={localesMenu.en}
          href={router.asPath}
          locale="en"
          active={locale === "en" ? true : false}
        />
        <MenuItem
          label={localesMenu.de}
          href={router.asPath}
          locale="de"
          active={locale === "de" ? true : false}
        />
        <MenuItem
          label={localesMenu.fr}
          href={router.asPath}
          locale="fr"
          active={locale === "fr" ? true : false}
        />
        <MenuItem
          href={router.asPath}
          locale="ja"
          label={localesMenu.ja}
          lastOfType
          active={locale === "ja" ? true : false}
        />
      </Box>
    </Box>
  );
}

export default LocalesMenu;

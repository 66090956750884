import { ApolloClient, InMemoryCache } from "@apollo/client";

import getConfig from "./config.js";

const client = new ApolloClient({
  uri: `${getConfig("API_BASE")}/api/graphql/`,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
    },
  },
});

export default client;

function GradientLine({ animate, animationDelay }) {
  let styles;
  if (animationDelay) {
    styles = {
      animationDelay: `${animationDelay}ms`,
    };
  }

  return (
    <div
      className={`gradient-line${animate ? " gradient-line--animate" : ""}`}
      style={styles}
    />
  );
}

GradientLine.defaultProps = {
  animate: true,
};

export default GradientLine;

import { useContext } from "react";

import { AppContext } from "./AppContext";
import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import Button from "./Button";
import CapSize from "./CapSize";
import CloseIcon from "../assets/close-icon.svg";
import FadeInItem from "./FadeInItem";
import GradientLine from "./GradientLine";
import Legal from "./Legal";
import Link from "next/link";
import SlideUpItem from "./SlideUpItem";
import SmallWhiteCaps from "./SmallWhiteCaps";
import SocialIcons from "./SocialIcons";
import { menuTransitionDuration } from "../core/constants";
import { useRouter } from "next/router";
import {
  menuItemsPrimary,
  solutionSections,
  menuItemsSecondary,
  closeButton,
} from "../content/menu";

function MenuLink({ href, external, children, ...props }) {
  const router = useRouter();
  const { setMenuOpen } = useContext(AppContext);
  const active = href === router.pathname;

  if (external) {
    return (
      <a href={href} target="_blank">
        <CapSize
          fontFamily="medium"
          color={active ? "lime" : "white"}
          transition="color 300ms"
          hover="color: #C7FF00;"
          {...props}
        >
          {children}
        </CapSize>
      </a>
    );
  }

  return (
    <Link href={href} passHref>
      <CapSize
        as="a"
        onClick={() => {
          if (active) setMenuOpen(false);
        }}
        fontFamily="medium"
        color={active ? "lime" : "white"}
        transition="color 300ms"
        hover="color: #C7FF00;"
        {...props}
      >
        {children}
      </CapSize>
    </Link>
  );
}

function MenuItemSecondary({ href, external, children }) {
  return (
    <Box>
      <MenuLink
        href={href}
        external={external}
        fontSize={18}
        py={[14, null, 20, null]}
        display="inline-block"
      >
        {children}
      </MenuLink>
    </Box>
  );
}

function MenuItemPrimary({ href, children }) {
  return (
    <Box>
      <MenuLink
        href={href}
        fontSize={[34, 34, 48, 48]}
        uppercase
        letterSpacing="8px"
        py={[18, null, 30, null]}
        display="inline-block"
      >
        {children}
      </MenuLink>
    </Box>
  );
}

function Menu({ locale }) {
  const { menuOpen, setMenuOpen } = useContext(AppContext);
  const footerAnimDelay =
    menuItemsSecondary[locale].length * 50 + menuTransitionDuration;
  return (
    <div className="menu">
      <Box position="fixed" right={[30, null, 77, null]} top={29} zIndex={1}>
        <div
          className={`menu-background${
            menuOpen ? " menu-background--active" : ""
          }`}
          style={{ "--transitionDelay": `${menuTransitionDuration}ms` }}
        ></div>
      </Box>

      <Box position="fixed" width="100%" height="100%" zIndex={2}>
        <Box
          width="100%"
          height="100%"
          position="relative"
          display="flex"
          flexDirection="column"
          opacity={menuOpen ? 1 : 0}
          transition="opacity 200ms"
        >
          <BoxWithGutter
            height={60}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
            position="absolute"
            top={0}
            left={0}
          >
            <Button
              display="flex"
              alignItems="center"
              onClick={() => setMenuOpen(false)}
              py="6px"
              height="100%"
            >
              <SmallWhiteCaps mr="9px">{closeButton[locale]}</SmallWhiteCaps>
              <CloseIcon />
            </Button>
          </BoxWithGutter>
          <BoxWithGutter
            overflowY="scroll"
            flex="1"
            display="flex"
            flexDirection="column"
          >
            <Box
              width="100%"
              maxWidth={1017}
              mx="auto"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flex={1}
              pt={60}
            >
              <Box
                display="grid"
                gridTemplateColumns={["1fr", null, "1fr 1fr", null]}
                gridColumnGap={40}
                width="100%"
              >
                <Box>
                  <Box pb={20}>
                    <GradientLine
                      animate={menuOpen}
                      animationDelay={menuTransitionDuration}
                    />
                  </Box>
                  {menuItemsPrimary[locale].map((item, index) => {
                    const delay = index * 50 + menuTransitionDuration;
                    return (
                      <SlideUpItem
                        key={item.href}
                        animate={menuOpen}
                        animationDelay={delay}
                      >
                        <MenuItemPrimary href={item.href}>
                          {item.title}
                        </MenuItemPrimary>
                      </SlideUpItem>
                    );
                  })}
                </Box>

                <Box display={[null, null, "none", null]} py={20}>
                  <GradientLine
                    animate={menuOpen}
                    animationDelay={menuTransitionDuration}
                  />
                </Box>

                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gridColumnGap={[0, null, 40, null]}
                >
                  <Box>
                    <Box display={["none", null, "block", null]} pb={32}>
                      <GradientLine
                        animate={menuOpen}
                        animationDelay={menuTransitionDuration}
                      />
                    </Box>

                    <Box display="flex" flexDirection="column">
                      {solutionSections[locale].map((item, index) => {
                        const delay = index * 50 + menuTransitionDuration;
                        return (
                          <SlideUpItem
                            key={item.href}
                            animate={menuOpen}
                            animationDelay={delay}
                          >
                            <MenuItemSecondary
                              href={item.href}
                              external={item.external}
                            >
                              {item.title}
                            </MenuItemSecondary>
                          </SlideUpItem>
                        );
                      })}
                    </Box>
                  </Box>
                  <Box>
                    <Box display={["none", null, "block", null]} pb={32}>
                      <GradientLine
                        animate={menuOpen}
                        animationDelay={menuTransitionDuration}
                      />
                    </Box>
                    {menuItemsSecondary[locale].map((item, index) => {
                      const delay = index * 50 + menuTransitionDuration;
                      return (
                        <SlideUpItem
                          key={item.href}
                          animate={menuOpen}
                          animationDelay={delay}
                        >
                          <MenuItemSecondary
                            href={item.href}
                            external={item.external}
                          >
                            {item.title}
                          </MenuItemSecondary>
                        </SlideUpItem>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>

            <FadeInItem animate={menuOpen} animationDelay={footerAnimDelay}>
              <Box
                display={[null, null, "flex", null]}
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                pt={[22, null, 0, null]}
                pb={[40, null, 70, null]}
              >
                <Box display={[null, null, "none", null]} pb={20}>
                  <GradientLine
                    animate={menuOpen}
                    animationDelay={menuTransitionDuration}
                  />
                </Box>
                <SocialIcons />
                <Box pt={[15, null, 0, null]}>
                  <Legal locale={locale} />
                </Box>
              </Box>
            </FadeInItem>
          </BoxWithGutter>
        </Box>
      </Box>
    </div>
  );
}

export default Menu;

import { HOME_URL } from "../core/urls";
import { useContext, useEffect, useRef, useState } from "react";
import { useLottieScroll, useViewportSize } from "../core/hooks";

import { AppContext } from "../components/AppContext";
import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import CapSize from "./CapSize";
import Link from "next/link";
import LottiePlayer from "./LottiePlayer";
import Menu from "./Menu";
import NavItemText from "./NavItemText";
import SolutionsMenu from "./SolutionsMenu";
import CustomersMenu from "./CustomersMenu";
import LocalesMenu from "./LocalesMenu";
import debounce from "underscore/modules/debounce";
import logoData from "../assets/main-logo-spin.json";
import styled from "styled-components";
import { useRouter } from "next/router";

const MenuButtonBase = styled.button`
  --dotColor: #c7ff00;
  color: white;
  display: flex;
  align-items: center;
  padding: 6px 0;
  &:hover {
    --dotColor: #020a66;
    color: #c7ff00;
  }
`;

function MenuButton({ handleClick, locale }) {
  const dotStyles = {
    backgroundColor: "var(--dotColor)",
    transition: "background-color 200ms",
  };

  const menu = {
    en: "Menu",
    de: "Menü",
    fr: "Menu",
    ja: "メニュー",
  };

  const menuContent = menu[locale];

  return (
    <MenuButtonBase onClick={handleClick}>
      <CapSize
        uppercase
        fontSize={12}
        fontFamily="medium"
        letterSpacing={"2px"}
        transition="color 300ms"
        color="inherit"
        mr="9px"
      >
        {menuContent}
      </CapSize>
      <Box height={14} width={14} borderRadius="100%" style={dotStyles} />
    </MenuButtonBase>
  );
}

function Nav() {
  const { innerHeight } = useViewportSize();
  const router = useRouter();
  const { menuOpen, setMenuOpen } = useContext(AppContext);
  const [showBackground, setShowBackground] = useState(false);
  const [animate, setAnimate] = useState(false);
  const playerRef = useRef();
  const bodyRef = useRef();
  useLottieScroll(bodyRef, playerRef, 100, false);

  useEffect(() => {
    bodyRef.current = document.body;

    // Check if nav should be filled to start with
    if (window.scrollY > 0) {
      setShowBackground(true);
    }

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowBackground(true);
      } else {
        setShowBackground(false);
      }
    };
    const debouncedHandleScroll = debounce(handleScroll, 20);

    window.addEventListener("scroll", debouncedHandleScroll);

    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, []);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const nav = {
    en: {
      vision: "Vision",
    },
    de: {
      vision: "Vision",
    },
    fr: {
      vision: "Vision",
    },
    ja: {
      vision: "私たちのビジョン",
    },
  };

  const navContent = nav[router.locale];

  return (
    <>
      <BoxWithGutter
        height={60}
        display="flex"
        alignItems="center"
        position="fixed"
        top={0}
        zIndex={300}
        transition="opacity 800ms, transform 400ms ease-out"
        transitionDelay={0}
        opacity={animate ? 1 : 0}
        transform={animate ? "translate3d(0,0,0)" : "translate3d(0,-20px,0)"}
      >
        <Link href={HOME_URL} passHref>
          <a
            onClick={() => {
              if (router.pathname === HOME_URL) setMenuOpen(false);
            }}
          >
            <LottiePlayer
              ref={playerRef}
              autoplay={false}
              loop={false}
              src={logoData}
              style={{ height: "22px", width: "124px" }}
              rendererSettings={{
                preserveAspectRatio: "xMidYMid meet",
              }}
              pauseWhenNotInView={false}
            />
          </a>
        </Link>
      </BoxWithGutter>
      <Box
        zIndex={200}
        alignItems="center"
        position="fixed"
        top={0}
        width="100vw"
        height={innerHeight}
        height="-webkit-fill-available"
        height="fill-available"
        pointerEvents={menuOpen ? "all" : "none"}
      >
        <Menu locale={router.locale} />
      </Box>

      <BoxWithGutter
        height={60}
        borderBottom="2px solid rgba(245, 245, 244, 0.15)"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        position="fixed"
        top={0}
        width="100%"
        zIndex={100}
        transition="opacity 500ms"
        transitionDelay={0}
        opacity={animate ? 1 : 0}
      >
        <Box
          opacity={showBackground ? 1 : 0}
          backgroundColor="trailstoneNavy"
          position="absolute"
          height="100%"
          width="100%"
          top={0}
          left={0}
          transition="opacity 300ms ease-out"
          zIndex={1}
        ></Box>
        <Box position="relative" zIndex={2} height="100%">
          <Box
            alignItems="center"
            display={["none", null, "flex", null]}
            height="100%"
          >
            <Box
              transition="opacity 800ms, transform 400ms ease-out"
              transitionDelay={200}
              opacity={animate ? 1 : 0}
              transform={
                animate ? "translate3d(0,0,0)" : "translate3d(0,-20px,0)"
              }
            >
              <LocalesMenu locale={router.locale} />
            </Box>
            <Box
              transition="opacity 800ms, transform 400ms ease-out"
              transitionDelay={100}
              opacity={animate ? 1 : 0}
              transform={
                animate ? "translate3d(0,0,0)" : "translate3d(0,-20px,0)"
              }
            >
              <NavItemText href={HOME_URL} label={navContent.vision} />
            </Box>
            <Box
              display="flex"
              transition="opacity 800ms, transform 400ms ease-out"
              transitionDelay={200}
              opacity={animate ? 1 : 0}
              transform={
                animate ? "translate3d(0,0,0)" : "translate3d(0,-20px,0)"
              }
            >
              <SolutionsMenu locale={router.locale} />
            </Box>
            <Box
              transition="opacity 800ms, transform 400ms ease-out"
              transitionDelay={300}
              opacity={animate ? 1 : 0}
              transform={
                animate ? "translate3d(0,0,0)" : "translate3d(0,-20px,0)"
              }
            >
              <CustomersMenu locale={router.locale} />
            </Box>
            <Box
              transition="opacity 800ms, transform 400ms ease-out"
              transitionDelay={400}
              opacity={animate ? 1 : 0}
              ml={35}
              transform={
                animate ? "translate3d(0,0,0)" : "translate3d(0,-20px,0)"
              }
            >
              <MenuButton
                handleClick={() => {
                  setMenuOpen(true);
                }}
                locale={router.locale}
              />
            </Box>
          </Box>
          <Box
            alignItems="center"
            display={["flex", null, "none", null]}
            height="100%"
          >
            <Box
              transition="opacity 800ms, transform 400ms ease-out"
              transitionDelay={200}
              opacity={animate ? 1 : 0}
              transform={
                animate ? "translate3d(0,0,0)" : "translate3d(0,-20px,0)"
              }
            >
              <LocalesMenu locale={router.locale} />
            </Box>
            <Box
              transition="opacity 800ms, transform 400ms ease-out"
              transitionDelay={0}
              opacity={animate ? 1 : 0}
              transform={
                animate ? "translate3d(0,0,0)" : "translate3d(0,-20px,0)"
              }
            >
              <MenuButton
                handleClick={() => {
                  setMenuOpen(!menuOpen);
                }}
                locale={router.locale}
              />
            </Box>
          </Box>
        </Box>
      </BoxWithGutter>
    </>
  );
}

export default Nav;

import CapSize from "./CapSize";

function SmallWhiteCaps({ children, ...props }) {
  return (
    <CapSize
      uppercase
      fontSize={12}
      fontFamily="medium"
      letterSpacing={"2px"}
      transition="color 300ms"
      hover="color: #C7FF00;"
      color="white"
      {...props}
    >
      {children}
    </CapSize>
  );
}

export default SmallWhiteCaps;

import Box from "./Box";
import CapSize from "./CapSize";
import Link from "next/link";

function MenuItem({ href, label, lastOfType, external }) {
  if (external) {
    return (
      <Box
        borderBottom={lastOfType ? "" : "1px solid rgba(245, 245, 244, 0.15)"}
      >
        <a href={href} target="_blank" style={{ display: "block" }}>
          <CapSize
            as="p"
            transition="color 300ms"
            py="14px"
            display="block"
            hover="color: #C7FF00;"
          >
            {label}
          </CapSize>
        </a>
      </Box>
    );
  }

  return (
    <Box borderBottom={lastOfType ? "" : "1px solid rgba(245, 245, 244, 0.15)"}>
      <Link href={href} passHref>
        <CapSize
          as="a"
          transition="color 300ms"
          py="14px"
          display="block"
          hover="color: #C7FF00;"
        >
          {label}
        </CapSize>
      </Link>
    </Box>
  );
}

export default MenuItem;

import Box from "./Box";
import CapSize from "./CapSize";
import { GET_LEGAL_PAGES } from "../core/core-queries";
import Link from "next/link";

import { useQuery } from "@apollo/client";

function LegalLink({ title, href }) {
  return (
    <Link href={`/${href}`} passHref>
      <Box as="a">
        <CapSize
          fontSize="12px"
          transition="color 300ms"
          hover="color: #C7FF00;"
          py="5px"
          display="inline-block"
        >
          {title}
        </CapSize>
      </Box>
    </Link>
  );
}

function Legal({ locale }) {
  const { loading, data } = useQuery(GET_LEGAL_PAGES, {
    variables: { locale },
  });
  if (loading) return "Loading...";

  return (
    <Box
      display="flex"
      flexDirection={["column-reverse", null, "row", null]}
      gap="15px"
    >
      <Box py="5px">
        <CapSize fontSize="12px">
          ©Trailstonegroup{new Date().getFullYear()}
        </CapSize>
      </Box>
      <Box display="flex" gap="15px">
        {data.legalPages.map((legalPage) => {
          return (
            <LegalLink
              key={legalPage.slug}
              title={legalPage.title}
              href={legalPage.slug}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default Legal;

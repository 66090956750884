import {
  ABOUT_URL,
  CAREERS_URL,
  CONTACT_URL,
  CUSTOMER_PORTAL_URL,
  HOME_URL,
  NATURAL_GAS_URL,
  NEWS_URL,
  PARTNERSHIPS_URL,
  RENEWABLES_URL,
  SOLUTIONS_URL,
  CORPORATE_URL,
  SUSTAINABILITY_URL,
} from "../core/urls";

export const menuItemsPrimary = {
  en: [
    { title: "Vision", href: HOME_URL },
    { title: "Solutions", href: SOLUTIONS_URL },
    { title: "Corporate", href: CORPORATE_URL },
  ],
  de: [
    { title: "Vision", href: HOME_URL },
    { title: "Lösungen", href: SOLUTIONS_URL },
    { title: "Corporate", href: CORPORATE_URL },
  ],
  fr: [
    { title: "Vision", href: HOME_URL },
    { title: "Solutions", href: SOLUTIONS_URL },
    { title: "Corporate", href: CORPORATE_URL },
  ],
  ja: [
    { title: "私たちのビジョン", href: HOME_URL },
    { title: "私たちのソリューション", href: SOLUTIONS_URL },
    { title: "Corporate", href: CORPORATE_URL },
  ],
};

export const solutionSections = {
  en: [
    { title: "Renewables", href: RENEWABLES_URL },
    { title: "Natural Gas", href: NATURAL_GAS_URL },
    { title: "Strategic Partnerships", href: PARTNERSHIPS_URL },
  ],
  de: [
    { title: "Erneuerbare Energien", href: RENEWABLES_URL },
    { title: "Erdgas", href: NATURAL_GAS_URL },
    { title: "Strategische Partnerschaften", href: PARTNERSHIPS_URL },
  ],
  fr: [
    { title: "Énergies renouvelables", href: RENEWABLES_URL },
    { title: "Gaz naturel", href: NATURAL_GAS_URL },
    { title: "Partenariats stratégiques", href: PARTNERSHIPS_URL },
  ],
  ja: [
    { title: "再生可能エネルギー", href: RENEWABLES_URL },
    { title: "天然ガス", href: NATURAL_GAS_URL },
    { title: "戦略的パートナーシップ", href: PARTNERSHIPS_URL },
  ],
};

export const menuItemsSecondary = {
  en: [
    { title: "Sustainability", href: SUSTAINABILITY_URL },
    { title: "Contact", href: CONTACT_URL },
    { title: "Careers", href: CAREERS_URL },
    { title: "News", href: NEWS_URL },
    { title: "Customer Portal", href: CUSTOMER_PORTAL_URL, external: true },
  ],
  de: [
    { title: "Nachhaltigkeit", href: SUSTAINABILITY_URL },
    { title: "Kontakt", href: CONTACT_URL },
    { title: "Karriere", href: CAREERS_URL },
    { title: "Aktuelles", href: NEWS_URL },
    { title: "Kundenportal", href: CUSTOMER_PORTAL_URL, external: true },
  ],
  fr: [
    { title: "Durabilité", href: SUSTAINABILITY_URL },
    { title: "Contact", href: CONTACT_URL },
    { title: "Carrières", href: CAREERS_URL },
    { title: "Nouvelles", href: NEWS_URL },
    { title: "Portail clients", href: CUSTOMER_PORTAL_URL, external: true },
  ],
  ja: [
    { title: "サステナビリティ", href: SUSTAINABILITY_URL },
    { title: "お問い合わせ", href: CONTACT_URL },
    { title: "採用情報", href: CAREERS_URL },
    { title: "ニュース", href: NEWS_URL },
    { title: "カスタマーポータル", href: CUSTOMER_PORTAL_URL, external: true },
  ],
};

export const closeButton = {
  en: "Close",
  de: "Schließen",
  fr: "Fermer",
  ja: "閉じる",
};

import { useState, useEffect } from "react";
import { border, color, flexbox, layout, position, space } from "styled-system";
import CloseIcon from "../assets/close-icon.svg";
import Box from "./Box";
import Button from "./Button";
import EmbedBlock from "./EmbedBlock";
import styled from "styled-components";

const CloseButtonBase = styled.div`
  ${color}
  ${space}
  ${border}
  ${flexbox}
  ${layout}
  ${position}
  body:not(.is-touch) & {
    &:hover {
      #circle {
        opacity: 1 !important;
      }
    }
  }
`;

function VideoLightbox() {
  const [isOpen, setIsOpen] = useState(false);
  const [embed, setEmbed] = useState("");
  useEffect(() => {
    window.addEventListener("LightboxOpen", handleLightboxOpen);

    return () => {
      window.removeEventListener("LightboxOpen", handleLightboxOpen);
    };
  }, []);

  const handleLightboxOpen = (e) => {
    setEmbed(e.detail);
    document.body.style.overflow = "hidden";
    setIsOpen(true);
  };

  const handleClick = () => {
    document.body.style.overflow = "visible";
    setIsOpen(false);
    setEmbed("");
  };
  return (
    <>
      <Box
        opacity={isOpen ? 1 : 0}
        position="fixed"
        bottom={0}
        right={0}
        left={0}
        top={0}
        transition="opacity 400ms ease-in-out"
        zIndex={isOpen ? 500 : 0}
      >
        <Box
          height="100%"
          width="100%"
          background="linear-gradient(132deg, #05004f, #030034 67%, #020027)"
          overflow="hidden"
          mx="auto"
          display="flex"
          justifyContent="center"
          position="absolute"
          alignItems="center"
          px={[23, 50, 70, null]}
          py={[23, 50, 100, null]}
        >
          <CloseButtonBase
            position="absolute"
            top={25}
            right={[23, 50, 70, null]}
            borderRadius="100%"
            height={48}
            width={48}
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex={1}
          >
            <Button onClick={handleClick}>
              <CloseIcon />
              <Box
                id="circle"
                transition="opacity 300ms"
                border="2px solid #F5F5F4"
                borderRadius="100%"
                position="absolute"
                height="100%"
                width="100%"
                top={0}
                left={0}
                opacity={0.5}
              />
            </Button>
          </CloseButtonBase>
          <Box
            width="100%"
            height="100%"
            maxWidth={[1080, null, null, 1920]}
            my="auto"
          >
            {embed && <EmbedBlock value={embed} />}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default VideoLightbox;

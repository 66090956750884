import { useRef } from "react";
import { useRouter } from "next/router";
import { footerItemsCol1, footerItemsCol2 } from "../content/footer";
import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import CapSize from "./CapSize";
import FooterAnim from "./FooterAnim";
import Legal from "./Legal";
import Link from "next/link";
import SocialIcons from "./SocialIcons";

function FooterItem({ href, external, children }) {
  if (external) {
    return (
      <Box>
        <a
          href={href}
          target="_blank"
          style={{ padding: "14px 0", display: "inline-block" }}
        >
          <CapSize
            fontSize={18}
            fontFamily="medium"
            transition="color 300ms"
            hover="color: #C7FF00;"
          >
            {children}
          </CapSize>
        </a>
      </Box>
    );
  }

  return (
    <Box>
      <Link href={href} passHref>
        <CapSize
          as="a"
          fontSize={18}
          fontFamily="medium"
          transition="color 300ms"
          hover="color: #C7FF00;"
          py="14px"
          display="inline-block"
        >
          {children}
        </CapSize>
      </Link>
    </Box>
  );
}

function Footer() {
  const playerRef = useRef();
  const { locale } = useRouter();

  const footerItemsCol1Content = footerItemsCol1[locale];
  const footerItemsCol2Content = footerItemsCol2[locale];

  return (
    <BoxWithGutter
      pt={50}
      background={[
        "linear-gradient(90deg, rgba(2,0,39,1) 0%, rgba(2,10,102,1) 49%, rgba(0,0,244,1) 100%)",
        null,
        "linear-gradient(45deg, rgba(2,0,39,1) 0%, rgba(2,10,102,1) 49%, rgba(0,0,244,1) 100%)",
        null,
      ]}
    >
      <Box
        display="flex"
        flexDirection={["column", null, "row", null]}
        justifyContent="space-between"
        alignItems={["flex-end", null, "flex-start", null]}
      >
        <Box
          display="flex"
          width={["100%", null, "initial", null]}
          flexWrap="wrap"
        >
          <Box
            width={["50%", null, "initial", null]}
            mr={[null, null, 140, null]}
          >
            {footerItemsCol1Content.map((item) => {
              return (
                <FooterItem key={item.href} href={item.href}>
                  {item.title}
                </FooterItem>
              );
            })}
          </Box>
          <Box width={["50%", null, "initial", null]}>
            {footerItemsCol2Content.map((item) => {
              return (
                <FooterItem
                  key={item.href}
                  href={item.href}
                  external={item.external}
                >
                  {item.title}
                </FooterItem>
              );
            })}
          </Box>
          <Box display={[null, null, "none", null]} width="100%" pt={30}>
            <SocialIcons />
          </Box>
        </Box>
        <Box
          mt={[20, null, 0, null]}
          height={[113, null, 162, null]}
          width={[113, null, 162, null]}
          display={["none", null, "initial", null]}
        >
          <FooterAnim />
        </Box>
      </Box>
      <Box
        display={[null, null, "flex", null]}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display={["none", null, "initial", null]}>
          <SocialIcons />
        </Box>
        <Box py={[30, null, 40, null]}>
          <Legal locale={locale} />
        </Box>
      </Box>
    </BoxWithGutter>
  );
}

export default Footer;

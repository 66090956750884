function SlideUpItem({ animate, animationDelay, children }) {
  return (
    <div
      className={`slide-up${animate ? " slide-up--active" : ""}`}
      style={{ animationDelay: `${animationDelay}ms` }}
    >
      {children}
    </div>
  );
}

export default SlideUpItem;

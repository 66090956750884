const breakpoints = ["640px", "960px", "1280px"];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];

export const theme = {
  breakpoints,
  colors: {
    trailstoneNavy: "#020027",
    indigo: "#03003A",
    gulfBlue: "#020A66",
    blue: "#030039",
    cyan: "#3AFFC6",
    lime: "#C7FF00",
    haze: "#F5F5F4",
    transparentGrey: "rgba(245, 245, 244, 0.3)",
    greyTint: "rgba(245, 245, 244, 0.1)",
  },
  fonts: {
    bold: "EuclidCircularB-Bold, Helvetica, Arial, sans-serif",
    medium: "EuclidCircularB-Medium, Helvetica, Arial, sans-serif",
    regular: "EuclidCircularB-Regular, Helvetica, Arial, sans-serif",
  },
  capSizes: {
    bold: {
      height: 0.75,
      offset: 0,
    },
    medium: {
      height: 0.75,
      offset: 0,
    },
    regular: {
      height: 0.75,
      offset: 0,
    },
  },

  mediaQueries: {
    small: `@media screen and (min-width: ${breakpoints.sm})`,
    medium: `@media screen and (min-width: ${breakpoints.md})`,
    large: `@media screen and (min-width: ${breakpoints.lg})`,
  },
};

import Box from "./Box";
import LinkedInIcon from "../assets/LinkedIN.svg";

import TwitterIcon from "../assets/Twitter_Icn.svg";

function SocialIcons() {
  return (
    <Box display="grid" display="flex">
      <Box mr="10px">
        <a
          href="https://www.linkedin.com/company/trailstone-group/"
          target="_blank"
        >
          <LinkedInIcon />
        </a>
      </Box>
      <Box>
        <a href="https://twitter.com/Trailstonegroup" target="_blank">
          <TwitterIcon />
        </a>
      </Box>
    </Box>
  );
}

export default SocialIcons;

import {
  NATURAL_GAS_URL,
  PARTNERSHIPS_URL,
  RENEWABLES_URL,
  SOLUTIONS_URL,
} from "../core/urls";
import { useEffect, useState } from "react";

import Box from "./Box";
import MenuItem from "./MenuItem";
import Chevron from "../assets/chevron.svg";
import NavItemText from "./NavItemText";
import { useRouter } from "next/router";

function SolutionsMenu({ locale }) {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setIsOpen(false);
  }, [router.asPath]);

  const solutionsMenu = {
    en: {
      renewables: "Renewables",
      naturalGas: "Natural Gas",
      solutions: "Solutions",
      strategicPartnerships: "Strategic Partnerships",
    },
    de: {
      renewables: "Erneuerbare Energien",
      naturalGas: "Erdgas",
      solutions: "Lösungen",
      strategicPartnerships: "Strategische Partnerschaften",
    },
    fr: {
      renewables: "Énergies renouvelables",
      naturalGas: "Gaz naturel",
      solutions: "Solutions",
      strategicPartnerships: "Partenariats stratégiques",
    },
    ja: {
      renewables: "再生可能エネルギー",
      naturalGas: "天然ガス",
      solutions: "私たちのソリューション",
      strategicPartnerships: "戦略的パートナーシップ",
    },
  };

  const solutionsMenuContent = solutionsMenu[locale];

  return (
    <Box
      position="relative"
      ml={35}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      height={75}
    >
      <Box display="flex" height="100%" alignItems="center">
        <NavItemText
          href={SOLUTIONS_URL}
          label={solutionsMenuContent.solutions}
        />
        <Box pl="5px" display="flex" justifyConent="center" alignItems="center">
          <Chevron />
        </Box>
      </Box>

      <Box
        position="absolute"
        top={75}
        width={212}
        left={-53}
        borderRadius={15}
        border="1px solid rgba(245, 245, 244, 0.15)"
        backgroundColor="gulfBlue"
        px={20}
        py={16}
        transition="opacity 100ms, transform 100ms cubic-bezier(0.16, 1, 0.3, 1)"
        opacity={isOpen ? 1 : 0}
        transform={isOpen ? "translate3d(0,0,0)" : "translate3d(0,10px, 0)"}
        pointerEvents={isOpen ? "all" : "none"}
      >
        <Box
          position="absolute"
          top={-8}
          width="100%"
          left={0}
          display="flex"
          justifyContent="center"
        >
          <Box
            width="15px"
            height="15px"
            borderTopColor="rgba(245, 245, 244, 0.15)"
            borderTopWidth="1px"
            borderTopStyle="solid"
            borderRightColor="rgba(245, 245, 244, 0.15)"
            borderRightWidth="1px"
            borderRightStyle="solid"
            backgroundColor="gulfBlue"
            transform="rotate(-45deg)"
          />
        </Box>
        <MenuItem
          href={RENEWABLES_URL}
          label={solutionsMenuContent.renewables}
        />
        <MenuItem
          href={NATURAL_GAS_URL}
          label={solutionsMenuContent.naturalGas}
        />
        <MenuItem
          href={PARTNERSHIPS_URL}
          label={solutionsMenuContent.strategicPartnerships}
          lastOfType
        />
      </Box>
    </Box>
  );
}

export default SolutionsMenu;

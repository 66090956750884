function FadeInItem({ animate, animationDelay, children }) {
  return (
    <div
      className={`fade-in${animate ? " fade-in--active" : ""}`}
      style={{ animationDelay: `${animationDelay}ms` }}
    >
      {children}
    </div>
  );
}

export default FadeInItem;
